html,
body,
div {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: normal;
  background: #fff;
  font-family: 'Puritan', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #222;
  word-wrap: break-word;
  letter-spacing: 2px;
}

html,
body {
  background: #fff;
}

.container {
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 30px 50px 50px 50px;
}

.hero {
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: auto;
  min-height: 10vh;
  animation-duration: 2000ms;
  color: #777;
  font-size: 36px;
  animation-name: fadeIn;
  text-transform: uppercase;
  letter-spacing: 10px;
  position: relative;
}

.tagline {
  align-items: center;
  height: auto;
  font-size: 24px;
  text-align: center;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
  animation-duration: 2000ms;
  animation-name: fadeIn;
  margin-bottom: 5vh;
}

.logo {
  position: absolute;
  left: 0;
  width: 40px;
  height: 40px;
  filter: grayscale(100%);
}

.contact {
  position: absolute;
  font-size: 24px;
  text-align: right;
  right: 0;
  letter-spacing: 2px;
  line-height: 1.2;
  text-decoration: none;
  margin-top: 12px;
}

a {
  color: #222;
}

.works {
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.work {
  width: 40%;
  margin: 2.5%;
  height: 360px;
  /* border: 1px solid #ddd;   */
  /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1); */
}

.work-image {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.work-title {
  height: 80px;
  align-items: center;
  justify-content: space-around;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;;
}

/**
 * Animations
 */

@keyframes fadeIn {
 from {
   opacity: 0;
 }

 to {
   opacity: 1;
 }
}
